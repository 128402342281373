<template>
  <div class="gameRanking">
    <div class="topHeader" v-if="!$route.query.platform">
      <div class="left" @click="$router.back()"></div>
      <div class="order_text">{{ $route.query.title || '玫瑰花排行榜' }}</div>
      <div class="right"></div>
    </div>
    <div class="banner">
      <img src="../../assets/images/call.png" />
      <!-- <div class="left">
        <img src="../../assets/images/christmasText.png" alt="" />
      </div>
      <div class="right" style="opacity: 0">
        <img src="../../assets/images/christmasShu.png" alt="" />
      </div> -->
    </div>
    <div class="list">
      <div class="tab">
        <div class="tabItem" v-for="item in tabList" :key="item">
          {{ item }}
        </div>
      </div>
      <div
        class="listItem"
        :class="
          key === 0 ? 'one' : key === 1 ? 'two' : key === 2 ? 'there' : null
        "
        v-for="(item, key) in list"
        :key="key"
      >
        <div>{{ key + 1 }}</div>
        <div>{{ item.nickName || '--' }}</div>
        <div>{{ item.count || '--' }}</div>
      </div>
    </div>
    <div class="info">当前总成就值：{{ total }}</div>
    <div class="info">*不包含寄卖状态的玫瑰</div>
  </div>
</template>

<script>
export default {
  name: 'gameRanking',
  data() {
    return {
      tabList: ['排名', '昵称', '拥有数量'],
      list: [],
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$api.rugosaRoseRanking().then((e) => {
        if (e.code === 0) {
          this.list = e.data;
          this.total = e.total;
        } else {
          this.$toast(e.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.gameRanking {
  background: url(../../assets/images/christmasBg1.jpeg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  padding-bottom: 20px;
  .header {
    height: 50px;
    padding: 0 20px;
    position: relative;
    .left {
      width: 10px;
      height: 10px;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      top: 50%;
      position: absolute;
      transform: rotate(-45deg) translateY(-50%);
    }
  }
  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 0 17px 0 38px;
    margin: 20px 0 15px;
    .left {
    }
    .right {
    }
    img {
      max-width: 100%;
    }
  }
  .list {
    background: #fee7e3;
    border-radius: 10px;
    margin: 0 17px 15px;
    padding: 10px 0 0;
    min-height: 100px;
    .info {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      // line-height: 35px;
    }
    &.minHeight {
      min-height: 200px;
    }
    .tab {
      display: flex;
      .tabItem {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #b20f67;
        flex: 1;
        text-align: center;
        padding: 10px 0;
        // &:nth-child(3) {
        //   flex: 2;
        // }
        // &:nth-child(4),
        // &:nth-child(5) {
        //   flex: 1.5;
        // }
      }
    }
    .listItem {
      display: flex;
      align-items: center;
      padding: 10px 0;
      &:nth-child(even) {
        background: #ffdad3;
      }
      div {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #977363;
        flex: 1;
        text-align: center;
        min-width: 0px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        padding: 0 3px;
        // &:nth-child(3) {
        //   flex: 2;
        // }
        // &:nth-child(4),
        // &:nth-child(5) {
        //   flex: 1.5;
        // }
      }
      &.one {
        div {
          color: #d14546;
        }
      }
      &.two {
        div {
          color: #fc9120;
        }
      }
      &.there {
        div {
          color: #e1b200;
        }
      }
    }
  }
  .info {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    padding-left: 30px;
  }
}
.topHeader {
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .left {
    width: 10px;
    height: 10px;
    left: 20px;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    top: 50%;
    position: absolute;
    transform: rotate(-45deg) translateY(-50%);
  }
  .right {
    position: absolute;
    transform: translateY(-50%);
    right: 16px;
    top: 50%;
    font-size: 12px;
  }
  .order_text {
    width: 50%;
    font-size: 18px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
  }
}
</style>
